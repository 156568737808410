.container {
  h1 {
    @apply mt-6 mb-6;
  }

  input {
    @apply mt-4 mb-4;
  }

  textarea {
    @apply resize w-full mt-4 mb-4;
  }

  .password {
    @apply w-full mt-4;
  }
  .show-password {
    @apply inline flex-row items-center;
    input {
      @apply pl-1;
    }
    label {
      @apply pr-1;
    }
  }
  .password-input {
    @apply w-full h-6 mt-1.5 mb-6;
  }
  .password-hint {
    @apply w-full h-[4.5rem] resize mt-4 mb-4;
  }

  .outlined-input {
    @apply outline outline-2 font-mono text-base;

    &:focus {
      @apply outline-dotted outline-1;
      outline-color: blue;
    }
  }

  .secret-type-form {
    @apply flex align-middle;

    div {
      @apply pl-2 pr-2;
    }
    label {
      @apply pl-1;
    }
  }

  .action {
    @apply mb-4;

    div {
      @apply mt-4;
    }
  }

  .details {
    @apply mt-4 mb-4;
  }
}
